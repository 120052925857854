import { useIsMerchantImpersonation } from "src/queries/user";
import { useMultiAccountFeature } from "src/libraries/organisation/multi-accounts/hooks/useMultiAccountFeature";
import { useAccountList } from "src/libraries/organisation/multi-accounts/hooks/useAccountList";

export const useCanViewAccountFilters = () => {
  const { hasMultiAccountV2Feature } = useMultiAccountFeature();
  const { isViewingDifferentOrganisation } = useIsMerchantImpersonation();

  const { accounts } = useAccountList({ showAllAccounts: true });

  const hasParentAccountAccess = !!accounts.find((account) => account.isParent);

  const shouldShowFilters =
    !isViewingDifferentOrganisation ||
    (isViewingDifferentOrganisation && !hasParentAccountAccess);

  return {
    canViewAccountFilters: hasMultiAccountV2Feature && shouldShowFilters,
  };
};
